import React from 'react';
import {getFromLocalStorage} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Advice, Button, Icons, Input, Link, Text} from 'components';
import * as AppEnv from 'app-env';

interface Header {
  search: string;
  isUnreadFilter: boolean;
  setSearch: AppEnv.SetState<string>;
  setIsUnreadFilter: AppEnv.SetState<boolean>;
}

const Header = ({
  search,
  isUnreadFilter,
  setSearch,
  setIsUnreadFilter
}: Header) => {
  const {i18n, t} = useTranslation();

  return (
    <div className="dialogs__header">
      {!getFromLocalStorage('feedback-2025-01') && (
        <div style={{padding: '12px 12px 0'}}>
          <Advice
            iconSize={24}
            id="feedback-2025-01"
            style={{padding: '4px 8px'}}
          >
            <Text size={14}>
              <Link
                href={
                  i18n.resolvedLanguage == 'ru'
                    ? 'https://forms.gle/qFAzMMaxyXQgHiEd9'
                    : 'https://forms.gle/q3hiYRViq7FYJv2a9'
                }
                isTargetBlank
              >
                {`🚀 ${t`Help us be better`}`}
              </Link>
            </Text>
          </Advice>
        </div>
      )}

      <div className="dialogs__header-wrapper">
        <div style={{width: 32}} />
        <Text size={16} textAlign="center">{t`All chats`}</Text>

        <Button
          color="transparent"
          tooltip={isUnreadFilter ? t`Show all` : t`Show unread`}
          onClick={() => setIsUnreadFilter(prevValue => !prevValue)}
        >
          <Icons.Filter color={isUnreadFilter ? 'green' : undefined} />
        </Button>
      </div>

      <div className="dialogs__search">
        <Input
          autoCapitalize="off"
          color="light-gray"
          isAlt
          name="new-search"
          onChange={setSearch}
          type="search"
          value={search}
        />
      </div>
    </div>
  );
};

export default Header;
